import React, { useState } from "react"
import axios from "axios"
import { applyDiscountCode } from "../api/common"
const ApplyDiscount = props => {
  const [discountCode, setDiscountCode] = useState("")
  const [validating, setValidating] = useState(false)
  const [couponDiscount, setcouponDiscount] = useState("")
  const handleValidate = async () => {
    // Check if discountCode length is less than 3
    if (discountCode.length <= 3) {
      console.error("Discount code length must be less than 3 characters.")
      return
    }

    setValidating(true)

    const payload = {
      discountCode: discountCode,
      custId: props.user.uid, // Assuming props.user contains the user information with uid
    }

    try {
      const couponDetails = await applyDiscountCode({ payload }) // Call your applyDiscount function
      // console.log("Discount Response", couponDetails)

      setcouponDiscount(couponDetails.data)

      // console.log("couponDiscount", couponDiscount)
      // console.log("Discount Percentage", couponDiscount.discountPercentage)
      if (couponDetails.data.status === "success") {
        props.buttonClick(
          couponDetails.data.couponDetails.discountPercentage,
          discountCode
        )
      } else {
        props.buttonClick(0, discountCode)
      }
    } catch (error) {
      console.error("Error validating discount code:", error)
    } finally {
      setValidating(false)
    }
  }
  return (
    <div className="row">
      <div className="col-md-9 col-7 noRightPadding">
        <input
          className="form-control"
          style={{ marginBottom: "16px", borderRadius: "10px" }}
          placeholder="Discount Code"
          value={discountCode}
          onChange={e => {
            setDiscountCode(e.target.value)
          }}
        />
      </div>
      <div className="col-md-3 col-5 noLeftPadding">
        <button
          className="btn applyCoupon"
          onClick={handleValidate}
          disabled={discountCode.length < 3}
        >
          {validating ? "Validating..." : "Apply Now"}
        </button>
      </div>
      <div className="col-md-12">
        {couponDiscount &&
        couponDiscount.status === "success" &&
        couponDiscount.couponDetails &&
        couponDiscount.couponDetails.spaceTypes &&
        couponDiscount.couponDetails.spaceTypes.includes(props.spaceType) ? (
          <p style={{ color: "#16cc0d" }}>
            <i className="fa fa-check-circle" aria-hidden="true"></i>{" "}
            {parseFloat(
              couponDiscount.couponDetails.discountPercentage
            ).toFixed(1)}
            % Discount Applied
          </p>
        ) : null}
        {couponDiscount && couponDiscount.status != "success" ? (
          <p style={{ color: "#ad002d" }}>
            <i class="fa fa-times-circle" aria-hidden="true"></i>{" "}
            {couponDiscount.message}
          </p>
        ) : null}
      </div>
    </div>
  )
}

export default ApplyDiscount
